
.wechat-pay-container {
    text-align: center;
    padding: 20px;
  }
  
  /* Title Styles */
  .wechat-pay-title {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  /* Button Styles */
  .wechat-pay-button {
    width: 80%;
    background-color: #2ea44f; /* 微信支付绿色 */
    color: #fff;
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  